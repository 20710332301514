import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useParams, Link } from "react-router-dom";
import GetNoOfPosition from "../../../common-component/GetNoOfPosition";
import RateCard from "../RateCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";
import { verifyJobRequirementInfo } from "../../../function/client/checkRequired";
import { jobRequirementInfo } from "../../../function/client/validateField";
import {
  editRequirementInfo,
  requirementInfo,
} from "../../../api/client/requirementInfo";
import { getRequirementInfo } from "../../../api/client/getRequirementInfo";
import { MinMaxDuration } from "../../../function/client/minMaxDuration";
import { durationData } from "../../../function/client/getDurationData";
import {
  receditRequirementInfo,
  recrequirementInfo,
} from "../../../api/recruiter/client/BuildRequirement/requirementInfo";
import {
  freeditRequirementInfo,
  freerequirementInfo,
} from "../../../api/freelance/client/BuildRequirement/requirementInfo";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { locationListSearch } from "../../../api/common/locationList";
import { getrequirementParsingData } from "../../../api/client/requirementParsing";
import { recgetrequirementParsingData } from "../../../api/recruiter/client/requirementParsing";
import { freegetrequirementParsingData } from "../../../api/freelance/client/requirementParsing";
import { getDetails } from "../../../api/client/getDetails";
import { recgetDetails } from "../../../api/recruiter/getDetails";
import { freegetDetails } from "../../../api/freelance/getDetails";
import { useHistory } from 'react-router-dom';
// import { useStepContext } from "../../../store-Jobrequirement/step-context";


const JobRequirment1 = (props) => {
  // const { currentStep, visitedSteps, updateStep } = useStepContext();
  // return jobrequirement first page
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [hiringType, setHiringType] = useState(true);
  const [workType, setWorkType] = useState(true);
  const [date, setDate] = useState(new Date());
  const [companyName, setCompanyName] = useState('');
  const [information, setInformation] = useState({
    jobTitle: "",
    clientName: "",
    location: "",
    domain: "",
    minDuration: "",
    interviewType: "",
    no_of_position: "",
    maxDuration: "",
    rateCardValue: "",
    travelRequirement: "",
    uniqueId: "",
  });

  const [error, setError] = useState({
    jobTitle: "",
    clientName: "",
    location: "",
    domain: "",
    minDuration: "",
    interviewType: "",
  });

  const [skillNavigation, setSkillNavigation] = useState(false);
  const [academicNavigation, setAcademicNavigation] = useState(false);
  const [securityNavigation, setSecurityNavigation] = useState(false);
  const [descriptionNavigation, setDescriptionNavigation] = useState(false);
  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("dashboardfont").innerText = "Home";
    document.getElementById("dashboardbtn").style.display = "inline-block";
    document.getElementById("postjobid").style.display = "none";
  } catch { }

  // const steps = [
  //   { id: 1, name: 'Step 1', color: '#3D007A' },
  //   { id: 2, name: 'Step 2', color: 'transparent' },
  //   { id: 3, name: 'Step 3', color: 'transparent' },
  //   { id: 4, name: 'Step 4', color: 'transparent' },
  //   { id: 5, name: 'Step 5', color: 'transparent' }
  // ];
  const steps = [
    { id: 1, name: 'Step 1', color: '#3D007A', linkIndex: null },
    { id: 2, name: 'Step 2', color: 'transparent', linkIndex: skillNavigation ? 1 : null },
    { id: 3, name: 'Step 3', color: 'transparent', linkIndex: academicNavigation ? 2 : null },
    { id: 4, name: 'Step 4', color: 'transparent', linkIndex: securityNavigation ? 3 : null },
    { id: 5, name: 'Step 5', color: 'transparent', linkIndex: descriptionNavigation ? 4 : null }
  ];
  let btnRef = useRef();
  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const token = useSelector((state) => state.token);
  //for calendar
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="resumeformcalendarfield w-100"
      onClick={onClick}
      ref={ref}
    >
      <label className="margin-top-10px">{value}</label>
      <i
        className="fas fa-calendar-alt font-size-20px margin-top-10px"
      ></i>
    </button>
  ));

  function MaxDuration() {
    if (information.minDuration) {
      const val = [];
      durationData.map((obj) => {
        if (Object.keys(obj)[0] === information.minDuration)
          val.push(obj[information.minDuration]);
      });
      const result = [information.maxDuration];
      try {
        val[0].map((val) => {
          if (val !== information.maxDuration) result.push(val);
        });
      } catch { }
      const option = result.map((value, index) => {
        if (index === 0)
          return (
            <option key={index} value={value} selected>
              {value} Months
            </option>
          );
        return (
          <option key={index} value={value}>
            {value} Months
          </option>
        );
      });
      return option;
    } else
      return (
        <option value={"Select"} disabled>
          Select
        </option>
      );
  }

  const rateValError = [
    "W2Witherror",
    "W2Withouterror",
    "c2herror",
    "c2cerror",
    "W2Hourlyerror",
    "W2Hourlywithouterror",
    "1099error",
    "1099Withouterror"
  ];

  const rateValCheckbox = [
    "W2WithCheck",
    "W2WithoutCheck",
    "C2HCheck",
    "C2CCheck",
    "W2HourlyWithCheck",
    "W2HourlyWithoutCheck",
    "N1099WithCheck",
    "N1099WithoutCheck"
  ];

  async function handleClick(e) {
    e.preventDefault();
    const target = e.target;
    const minDuration = target.minDuration.value;
    const number_of_position = target.no_of_position.value;
    const maxDuration = target.maxDuration.value;
    const travel = target.travelRequirement.value;
    const taxTerm = target.taxterm.value;
    const duration = target.duration.value;
    const rate = +target.rate.value;
    const hiringType = target.hiring.value;
    const workingType = target.working.value;

    const rateError = document.getElementById("rateerror").innerHTML
    if (taxTerm === "Select") {
      document.getElementById("employmenterror").innerHTML = "Please enter the employment type"
      document.getElementsByClassName("selectemploy")[0].style.cssText = "border: 1px solid red"
    }
    if (duration === "Select") {
      document.getElementById("rateTypeerror").innerHTML = "Please enter the rate type"
      document.getElementsByClassName("selectrateType")[0].style.cssText = "border: 1px solid red"
    }
    if (target.rate.value === "") {
      document.getElementById("rateerror").innerHTML = "Please enter the rate"
      document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
    }
    const rateTableError = rateValError.map((key) => {
      const field = document.getElementsByClassName(`${key}`)[0].innerHTML
      if (field !== "") {
        return false;
      }
      else {
        return true
      }
    })
    var rateTableCheckBox = [];
    rateValCheckbox.map((key) => {
      rateTableCheckBox = [...rateTableCheckBox, document.getElementsByName(`${key}`)[0].checked]
    })
    const TotalCheckValue = rateTableCheckBox.every(value => value === false)
    if (TotalCheckValue && target.rate.value !== "" && rateError === "") {
      document.getElementsByClassName("totalCheckError")[0].innerHTML = "Select at least one checkbox"
    }
    let registerData = {
      hiringType: hiringType,
      workType: workingType,
      jobTitle: information.jobTitle,
      clientName: information.clientName,
      numberOfPosition: number_of_position,
      location: information.location,
      domain: information.domain,
      startDate: date.toISOString().slice(0, 10),
      minDuration: minDuration,
      maxDuration: maxDuration === "Months" ? "" : maxDuration,
      interviewType: information.interviewType,
      travelRequirement: travel,
      employementType: taxTerm,
      rateType: duration,
      rate: rate,
    };
    if (target.W2WithCheck.checked) {
      registerData = {
        W2With: +target.W2With.value,
        ...registerData,
      };
    }
    if (target.W2WithoutCheck.checked) {
      registerData = {
        W2Without: +target.W2Without.value,
        ...registerData,
      };
    }
    if (target.W2HourlyWithCheck.checked) {
      registerData = {
        W2HourlyWith: +target.W2HourlyWith.value,
        ...registerData,
      };
    }
    if (target.W2HourlyWithoutCheck.checked) {
      registerData = {
        W2HourlyWithout: +target.W2HourlyWithout.value,
        ...registerData,
      };
    }
    if (target.C2HCheck.checked) {
      registerData = {
        C2H: +target.C2H.value,
        ...registerData,
      };
    }
    if (target.C2CCheck.checked) {
      registerData = {
        C2C: +target.C2C.value,
        ...registerData,
      };
    }
    if (target.N1099WithCheck.checked) {
      registerData = {
        N1099With: +target.N1099With.value,
        ...registerData,
      };
    }
    if (target.N1099WithoutCheck.checked) {
      registerData = {
        N1099Without: +target.N1099Without.value,
        ...registerData,
      };
    }
    let filteredValue = {};
    Object.keys(registerData)
      .filter((key) => {
        const value = registerData[key];
        if (
          registerData.workAuthorization === "Select" &&
          (key === "workAuthorization" ||
            key === "employementType" ||
            key === "rateType" ||
            key === "rate")
        )
          return false;
        else if (value || value === false) return true;
        return false;
      })
      .map((key) => {
        filteredValue = { [key]: registerData[key], ...filteredValue };
      });

    if (!verifyJobRequirementInfo(error, setError, information)) return;
    const result = Object.values(error)
      .map((data) => {
        if (data) return true;
        return false;
      })
      .some((data) => {
        if (data) return true;
        return false;
      });

    if (!result && rateError === "" && !rateTableError.includes(false) && !TotalCheckValue) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      if (!id) {
        const response = isrecruiterCandidate
          ? await recrequirementInfo(token, filteredValue)
          : isFreelance
            ? await freerequirementInfo(token, filteredValue)
            : await requirementInfo(token, filteredValue);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setInformation({
            ...information,
            uniqueId: payload.generateunique,
          });
          setRedirect(true);
        } else {
          document.getElementsByClassName("requirementErr")[0].innerText =
            "please fill all required fields";
          btnRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      } else {
        const editResponse = isrecruiterCandidate
          ? await receditRequirementInfo(token, filteredValue, id)
          : isFreelance
            ? await freeditRequirementInfo(token, filteredValue, id)
            : await editRequirementInfo(token, filteredValue, id);
        if (editResponse.status === 200) {
          setRedirect(true);
        } else {
          btnRef.current.removeAttribute("disabled");
          setLoader(false);
          document.getElementsByClassName("requirementErr")[0].innerText =
            "please fill all required fields";
        }
      }
    }
  }

  //get job requirementdetails
  useEffect(() => {
    async function callItSelf() {
      try {
        //requirement parsing data
        const profileResponse = isrecruiterCandidate
          ? await recgetDetails(token)
          : isFreelance
            ? await freegetDetails(token)
            : await getDetails(token);
        if (profileResponse.status === 200) {
          const profileData = await profileResponse.json();
          const profilePayload = profileData.payload;
          setCompanyName(profilePayload.companyName)
          if (refId) {
            const response = isrecruiterCandidate
              ? await recgetrequirementParsingData(token, refId)
              : isFreelance
                ? await freegetrequirementParsingData(token, refId)
                : await getrequirementParsingData(token, refId)
            if (response.status === 200) {
              const data = await response.json();
              const payload = data.payload.parsedData;
              setInformation({
                jobTitle: payload.jobTitle !== null && payload.jobTitle,
                clientName: hiringType ? profilePayload.companyName : payload.clientName !== null && payload.clientName,
                location: payload.city !== null && payload.city,
                domain: payload.domain !== null && payload.domain,
                no_of_position: payload.positions !== null && payload.positions >= 1 && payload.positions
              })
            }
          } else {
            if (!information.jobTitle) {
              if (hiringType) {
                setInformation({
                  ...information,
                  clientName: profilePayload.companyName
                })
              }
              const response = isrecruiterCandidate
                ? await recgetRequirementInfo(token, id)
                : isFreelance
                  ? await freegetRequirementInfo(token, id)
                  : await getRequirementInfo(token, id);
              if (response.status === 200) {
                const data = await response.json();
                const payload = data.payload.requirement;
                if (payload.jobTitle.length !== 0) {
                  setSkillNavigation(true);
                  document.getElementsByClassName("hidebtn")[0].style.display =
                    "none";
                }
                if (payload.hiringType === "INTERNAL_HIRING") {
                  setHiringType(true);
                } else {
                  setHiringType(false);
                }
                if (payload.workType === "WORK_REMOTELY") {
                  setWorkType(true);
                } else {
                  setWorkType(false);
                }
                try {
                  setInformation({
                    jobTitle: payload.jobTitle,
                    clientName: payload.clientName,
                    no_of_position: payload.numberOfPosition,
                    location: payload.location || "",
                    domain: payload.domain,
                    minDuration: payload.minDuration,
                    maxDuration: payload.maxDuration,
                    interviewType: payload.interviewType,
                    travelRequirement: payload.travelRequirement,
                    rate: payload.rate,
                    //preferredEmployment: payload.employementType,
                    rateType: payload.rateType,
                    uniqueId: id,
                  });
                  document.getElementsByClassName("selectemploy")[0].value = payload.employementType
                  setDate(new Date(payload.startDate || null));
                } catch { }
                if (payload.ClientPrimarySkills.length !== 0) {
                  setAcademicNavigation(true);
                }
                if (
                  payload.AcademicCertificates[0].academicsQualification.length !==
                  0
                ) {
                  setSecurityNavigation(true);
                }
                if (payload.securityClearanceRequired.length !== 0) {
                  setDescriptionNavigation(true);
                }
              }
            }
          }
        }
      } catch { }
    }
    callItSelf();
  }, []);
  // useEffect(() => {
  //   updateStep(1); // Mark this step as visited
  // }, [updateStep]);
  function submitEnabled() {
    try {
      document.getElementsByClassName("hidebtn")[0].style.display = "block";
      setSkillNavigation(false);
      setAcademicNavigation(false);
      setSecurityNavigation(false);
      setDescriptionNavigation(false);
    } catch { }
  }

  //Location integration
  const [citylist, setCityList] = useState([]);
  const [cityShow, setCityShow] = useState(false);

  async function handleLocationChange() {
    let data = information.location;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList(payload);
    } else if (response.status === 404) {
      setCityShow(false);
      setError({
        ...error,
        location: "please enter valid city",
      });
    }
  }
  useEffect(() => {
    handleLocationChange();
  }, [information.location]);

  async function selectedLocation(city) {
    setCityShow(false);
    setInformation({
      ...information,
      location: city,
    });
    setError({
      ...error,
      location: "",
    });
  }
  function locationValid() {
    let locationValue = information.location;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (information.location.length !== 0) {
      if (found) {
        setError({
          ...error,
          location: "",
        });
      } else {
        setError({
          ...error,
          location: "please enter valid city",
        });
      }
    }
  }
  // for location outside click
  const jobref = React.useRef();
  useEffect(() => {
    const locHandleClickOutside = (event) => {
      if (
        cityShow &&
        jobref.current &&
        !jobref.current.contains(event.target)
      ) {
        document.getElementById("firstLocCodeDiv").style.display = "none";
        locationValid();
      }
      document.body.removeEventListener("click", locHandleClickOutside);
    };
    document.body.addEventListener("click", locHandleClickOutside);
  }, [cityShow, citylist, information.location]);

  // autofocus
  // function myFunction() {
  //   setHiringType(false);
  //   var x = document.getElementById("myText").select();
  // }


  const history = useHistory();
  // const navigateToStep = (stepNumber) => {
  //   // updateStep(stepNumber); 

  //   const step = steps.find((s) => s.id === stepNumber);

  //   if (step && step.linkIndex !== null) {
  //     const link = props.link[step.linkIndex];
  //     if (link) {
  //       history.push(`${link}/${information.uniqueId}`);
  //     }
  //   }
  // };

  // const navigateToStep = (stepNumber) => {
  //   updateStep(stepNumber);
  //   switch (stepNumber) {
  //     case 1:
  //       history.push(`${props.link[0]}/${information.uniqueId}`);
  //       break;
  //     case 2:
  //       history.push(`${props.link[1]}/${information.uniqueId}`);
  //       break;
  //     case 3:
  //       history.push(`${props.link[2]}/${information.uniqueId}`);
  //       break;
  //     case 4:
  //       history.push(`${props.link[3]}/${information.uniqueId}`);
  //       break;
  //     case 5:
  //       history.push(`${props.link[4]}/${information.uniqueId}`);
  //       break;
  //     default:
  //       break;
  //   }
  // };
  // const checkNextStep = (index) => {
  //   // Add logic to check if step can be navigated
  //   if (index <= currentStep - 1) {
  //     navigateToStep(index + 1);
  //   }
  // };
  const checkNextStep = (index) => {
    // if (visitedSteps.includes(index + 1)) {
    const step = steps.find((s) => s.id === index + 1);

    if (step && step.linkIndex !== null) {
      const link = props.link[step.linkIndex];
      if (link) {
        history.push(`${link}/${information.uniqueId}`);
      }
    }
    // }
  };

  return (
    <div className={"col"}>
      <form className={"form-group"} onSubmit={handleClick}>
        {/* <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> */}

        <div className={"row mx-lg-5 mx-1 pl-lg-5 mt-lg-5 resumealignment pl-3 pl-lg-0"}>
          <div className="step-container pl-lg-4">
            {steps.map((step, index) => (
              <div key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 1 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }} onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}>
                {step.name}
              </div>
            ))}
          </div>
        </div>
        {/* <div className={"row mx-lg-5 pl-lg-5  resumealignment"}>
          <div className={"step-container pl-4"}>
            {steps.map((step, index) => (
              <div
                key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 1 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }}
                onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}
              >
                <label className="font-weight-500 font-size-14px colored-primary-black">
                  {step.name}
                </label>
              </div>
            ))}
          </div>
        </div> */}

        {/* <div className={"row mx-lg-5 mx-0  pl-lg-5 pl-0  resumealignment"}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <div
              className={"pt-1 pl-2 pr-2 border-radius-10px text-center background-colored-primary-white jobRequirment1-header-design-container-style"}
            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    className="font-weight-500 font-size-14px colored-primary-black"
                  >
                    Requirement <br />
                    info
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    className="colored-sky-blue jobRequirment1-Requirement-info-border"

                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {skillNavigation ? (
              <Link to={`${props.link[1]}/${information.uniqueId}`}>
                <div
                  className={"pl-3 pb-2 pr-3 pt-3 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center "}
                >
                  <label
                    className="colored-Silver-gray font-size-14px font-weight-500"
                  >
                    Skill
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pl-3 pb-2 pr-3 pt-3 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}

              >
                <label
                  className="colored-Silver-gray font-size-14px font-weight-500"
                >
                  Skill
                </label>
                <br />
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {academicNavigation ? (
              <Link to={`${props.link[2]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}

                >
                  <label
                    className="colored-Silver-gray font-size-14px font-weight-500"
                  >
                    Academics
                    <br />& Certificate
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}

              >
                <label
                  className="colored-Silver-gray font-size-14px font-weight-500"
                >
                  Academics
                  <br />& Certificate
                </label>
                <br />
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {securityNavigation ? (
              <Link to={`${props.link[3]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}

                >
                  <label
                    className="colored-Silver-gray font-size-14px font-weight-500"
                  >
                    Security Clearance
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}
              >
                <label
                  className="colored-Silver-gray font-size-14px font-weight-500"
                >
                  Security Clearance
                </label>
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {descriptionNavigation ? (
              <Link to={`${props.link[4]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}

                >
                  <label
                    className="colored-Silver-gray font-size-14px font-weight-500"
                  >
                    Job
                    <br />
                    Description
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style border-radius-10px background-colored-primary-white text-center"}

              >
                <label
                  className="colored-Silver-gray font-size-14px font-weight-500"
                >
                  Job
                  <br />
                  Description
                </label>
              </div>
            )}
          </div>
        </div> */}
        <div className={"pl-lg-1 pl-0 pr-lg-5 pr-0 mr-lg-5 mr-0 pt-5"}>
          <div className={"row px-lg-5 px-0  pr-lg-5 pr-0 mx-lg-3 mx-0 pl-3 pl-lg-0"}>
            <div className={"w-100 pb-5 pt-4 pl-lg-5 pl-3 contentholder"}>
              <div className="mb-4 d-inline-block font-weight-semibold font-size-26px" >
                Requirement info
              </div>
              <div className={"row"}>
                <div className={"col-md form-inline"}>
                  <label className={"resumeformquerytext pr-3"}>
                    Hiring Type
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="hiring"
                    value="INTERNAL_HIRING"
                    checked={hiringType}
                    onClick={() => {
                      setHiringType(true)
                      setInformation({
                        ...information,
                        clientName: companyName
                      })
                    }}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Internal hiring
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="hiring"
                    value="EXTERNAL_HIRING"
                    checked={!hiringType}
                    onClick={() => {
                      setHiringType(false);
                    }}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    External hiring
                  </label>
                </div>
              </div>
              <div className={"row pt-3"}>
                <div className={"col-md form-inline"}>
                  <label className={"resumeformquerytext pr-3 pl-1"}>
                    Work Type
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="working"
                    value="WORK_REMOTELY"
                    checked={workType}
                    onClick={() => setWorkType(true)}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Work remotely
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="working"
                    value="ON_SITE"
                    checked={!workType}
                    onClick={() => setWorkType(false)}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    On site
                  </label>
                </div>
              </div>
              <div className={"row pr-5 pt-3"}>
                <div className={"col-lg-4"}>
                  <label
                    htmlFor={"Jtitle"}
                    className={"dashboardforminputtext"}
                  >
                    Job Title<span className="colored-Bright-red">*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"Jtitle"}
                    name={"jobTitle"}
                    value={information.jobTitle}
                    style={{
                      border: `${error.jobTitle ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.jobTitle}
                  </font>
                </div>
                <div className={"col-lg-8"}>
                  <label htmlFor={"cname"} className={"dashboardforminputtext"}>
                    Client Name
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"myText"}
                    name={"clientName"}
                    maxLength={50}
                    disabled={hiringType ? true : false}
                    value={information.clientName}
                    style={{
                      border: `${error.clientName ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.clientName}
                  </font>
                </div>
              </div>
              <div className={"row pt-2 pr-5"}>
                <div className={"col-lg-4"}>
                  <label htmlFor={"NOS"} className={"resumeforminputtext"}>
                    Number Of position
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none"
                    }
                    id={"NOS"}
                    name={"no_of_position"}
                    value={information.no_of_position}
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  >
                    <GetNoOfPosition />
                  </select>
                </div>
                <div className={"col-md"} ref={jobref}>
                  <label htmlFor={"location"} className={"resumeforminputtext"}>
                    Location<span className="colored-Bright-red">*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"location"}
                    name={"location"}
                    value={information.location}
                    style={{
                      border: `${error.location ? "1px solid red" : ""}`,
                    }}
                    className={"form-control resumeforminputfield shadow-none"}
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                      setCityShow(true);
                    }}
                    autoComplete={"off"}
                    onClick={() => {
                      setCityShow(!cityShow);
                      locationValid();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.location}
                  </font>
                  <div
                    id={"firstLocCodeDiv"}
                    style={{
                      display: `${cityShow && information.location.length > 0
                        ? "block"
                        : "none"
                        }`,
                    }}
                  >
                    {citylist &&
                      citylist.length > 0 &&
                      citylist.map((data, index) => (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          value={data.city}
                          onClick={() => selectedLocation(data.city)}
                        >
                          {data.city}
                        </div>
                      ))}
                  </div>
                </div>
                <div className={"col-md"}>
                  <label htmlFor={"domain"} className={"resumeforminputtext"}>
                    Domain<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"domain"}
                    name={"domain"}
                    value={information.domain}
                    style={{
                      border: `${error.domain ? "1px solid red" : ""}`,
                    }}
                    className={"form-control resumeforminputfield shadow-none"}
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.domain}
                  </font>
                </div>
              </div>
              <div className={"row pt-2 pr-5"}>
                <div className={"col-lg-4"}>
                  <label
                    htmlFor={"startdate"}
                    className={"resumeforminputtext"}
                  >
                    Start Date
                  </label>
                  <DatePicker
                    selected={date}
                    required="true"
                    onChange={(date) => {
                      setDate(date);
                      submitEnabled();
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    customInput={<CustomInput />}
                  />
                </div>
                <div className={"col-lg-4"}>
                  <label htmlFor={"min"} className={"resumeforminputtext"}>
                    Min Duration<span className="colored-Bright-red">*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none"
                    }
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                    id={"min"}
                    name={"minDuration"}
                    value={information.minDuration}
                    style={{
                      border: `${error.minDuration ? "1px solid red" : ""}`,
                    }}
                  >
                    <MinMaxDuration
                      {...{ minMaxDuration: information.minDuration }}
                    />
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.minDuration}
                  </font>
                </div>
                <div className={"col-lg-4"}>
                  <label htmlFor={"max"} className={"resumeforminputtext"}>
                    Max Duration
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none"
                    }
                    id={"max"}
                    name={"maxDuration"}
                    value={information.maxDuration}
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  >
                    <MaxDuration />
                  </select>
                </div>
              </div>
              <div className={"row pt-2 pr-5"}>
                <div className={"col-lg-4"}>
                  <label htmlFor={"Itype"} className={"resumeforminputtext"}>
                    Interview type<span className="colored-Bright-red">*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none"
                    }
                    value={information.interviewType}
                    style={{
                      border: `${error.interviewType ? "1px solid red" : ""}`,
                    }}
                    id={"Itypw"}
                    name={"interviewType"}
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  >
                    <option value={""}>Select</option>
                    <option value={"TELEPHONE"}>Telephone</option>
                    <option value={"VIDEO"}>Video</option>
                    <option value={"IN_PERSON"}>In Person</option>
                    <option value={"TELEPHONE-VIDEO"}>Telephone-Video</option>
                    <option value={"TELEPHONE-IN_PERSON"}>
                      Telephone-In Person
                    </option>
                    <option value={"VIDEO-IN_PERSON"}>Video-In Person</option>
                    <option value={"TELEPHONE-VIDEO-IN_PERSON"}>
                      Telephone, Video & In-person
                    </option>
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.interviewType}
                  </font>
                </div>
                <div className={"col-lg-8"}>
                  <label htmlFor={"travel"} className={"resumeforminputtext"}>
                    Travel Requirement
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none"
                    }
                    id={"travel"}
                    name={"travelRequirement"}
                    value={information.travelRequirement}
                    onChange={(event) => {
                      jobRequirementInfo(
                        event,
                        error,
                        setError,
                        information,
                        setInformation
                      );
                      submitEnabled();
                    }}
                  >
                    <option value={"0%"}>0%</option>
                    <option value={"25%"}>25%</option>
                    <option value={"50%"}>50%</option>
                    <option value={"75%"}>75%</option>
                    <option value={"OCCASSIONAL"}>Occassional</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"pl-lg-1 pl-0 pr-lg-5 pr-0 mr-lg-5 mr-0"}>
          <div className={"row px-lg-5 px-0  pr-lg-5 pr-0 mx-lg-3 mx-0 pt-4 pl-3 pl-lg-0"}>
            <div className={"w-100  pb-2 px-lg-4 px-1 pr-lg-5 pr-0 contentholder"}>
              <RateCard
                {...{
                  workAuthorization: information.workAuthorization,
                  preferredEmployment: information.preferredEmployment,
                  rateType: information.rateType,
                  rate: information.rate,
                  setSkillNavigation: setSkillNavigation,
                  setAcademicNavigation: setAcademicNavigation,
                  setSecurityNavigation: setSecurityNavigation,
                  setDescriptionNavigation: setDescriptionNavigation,
                }}
              />
            </div>
          </div>
        </div>
        <div className="row pr-5">
          <div className="col-md offset-sm-9">
            <label
              className={"resumeforminputtext text-danger requirementErr font-weight-bold"}

            ></label>
          </div>
        </div>
        <div className={"row hidebox pl-3 pl-lg-0"}>
          <div className={"col pt-2 pb-2 pr-5"}>
            {skillNavigation ? (
              <>
                {redirect ? (
                  <Redirect to={`${props.link[1]}/${information.uniqueId}`} />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save hidebtn"}
                    ref={btnRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment1-loader-padding"
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  refId ? (
                    <Redirect
                      to={`${props.link[1]}/${information.uniqueId}/${refId}`}
                    />
                  ) : (
                    <Redirect to={`${props.link[1]}/${information.uniqueId}`} />
                  )
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save"}
                    ref={btnRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default React.memo(JobRequirment1);
