import { dateConverter } from "./dateConverter";

function postExperienceParseData(
  information,
  visibility,
  toDate,
  fromDate,
  toDate1,
  fromDate1,
  toDate2,
  fromDate2
) {
  const parseData = [
    {
      totalYearOfExperience: information.yearOfExp,
      totalYearOfExperienceInUS: information.yearOfExpUS,
      jobTitle: information.jobTitle,
      client: information.client,
      domain: information.domain,
      from: fromDate.toISOString().slice(0, 10),
      to: toDate.toISOString().slice(0, 10),
      location: information.location,
      projectSummary: information.projectSummary,
      rolesAndResponsibilities: information.roleAndResponse,
    },
  ];
  if (visibility.one) {
    parseData.push({
      totalYearOfExperience: information.yearOfExp1,
      totalYearOfExperienceInUS: information.yearOfExpUS1,
      jobTitle: information.jobTitle1,
      client: information.client1,
      domain: information.domain1,
      from: fromDate1.toISOString().slice(0, 10),
      to: toDate1.toISOString().slice(0, 10),
      location: information.location1,
      projectSummary: information.projectSummary1,
      rolesAndResponsibilities: information.roleAndResponse1,
    });
  }
  if (visibility.two) {
    parseData.push({
      totalYearOfExperience: information.yearOfExp2,
      totalYearOfExperienceInUS: information.yearOfExpUS2,
      jobTitle: information.jobTitle2,
      client: information.client2,
      domain: information.domain2,
      from: fromDate2.toISOString().slice(0, 10),
      to: toDate2.toISOString().slice(0, 10),
      location: information.location2,
      projectSummary: information.projectSummary2,
      rolesAndResponsibilities: information.roleAndResponse2,
    });
  }
  const keys = Object.keys(parseData[0]);
  parseData.map((object) => {
    keys.map((key) => {
      if ((!object[key] && object[key] === "") || object[key] === null)
        delete object[key];
    });
    return object;
  });

  return { experience: parseData };
}

function getExperienceParseData(
  payload,
  information,
  setInformation,
  setVisibility,
  setFromDate,
  setToDate,
  setFromDate1,
  setToDate1,
  setFromDate2,
  setToDate2
) {
  let parseData = {};
  if (payload[0]) {
    const currentObj = payload[0];
    try {
      parseData = {
        ...parseData,
        yearOfExp: currentObj.totalYearOfExperience,
        yearOfExpUS: currentObj.totalYearOfExperienceInUS,
        jobTitle: currentObj.jobTitle,
        client: currentObj.client,
        domain: currentObj.domain,
        location: currentObj.location,
        projectSummary: currentObj.projectSummary,
        roleAndResponse: currentObj.rolesAndResponsibilities,
      };
      setFromDate(new Date(currentObj.from));
      setToDate(new Date(currentObj.to));
    } catch {}
  }
  if (payload[1]) {
    try {
      setVisibility((state) => {
        return { ...state, one: true };
      });
      const currentObj = payload[1];

      parseData = {
        ...parseData,
        yearOfExp1: currentObj.totalYearOfExperience,
        yearOfExpUS1: currentObj.totalYearOfExperienceInUS,
        jobTitle1: currentObj.jobTitle,
        client1: currentObj.client,
        domain1: currentObj.domain,
        location1: currentObj.location,
        projectSummary1: currentObj.projectSummary,
        roleAndResponse1: currentObj.rolesAndResponsibilities,
      };
      setFromDate1(new Date(currentObj.from));
      setToDate1(new Date(currentObj.to));
    } catch {}
  }
  if (payload[2]) {
    try {
      setVisibility((state) => {
        return { ...state, two: true };
      });
      const currentObj = payload[2];
      parseData = {
        ...parseData,
        yearOfExp2: currentObj.totalYearOfExperience,
        yearOfExpUS2: currentObj.totalYearOfExperienceInUS,
        jobTitle2: currentObj.jobTitle,
        client2: currentObj.client,
        domain2: currentObj.domain,
        location2: currentObj.location,
        projectSummary2: currentObj.projectSummary,
        roleAndResponse2: currentObj.rolesAndResponsibilities,
      };
      setFromDate2(new Date(currentObj.from));
      setToDate2(new Date(currentObj.to));
    } catch {}
  }
  setInformation({ ...information, ...parseData });
}

function getExperienceFromParse(
  payload,
  information,
  setInformation,
  setVisibility,
  setFromDate,
  setToDate,
  setFromDate1,
  setToDate1,
  setFromDate2,
  setToDate2
) {
  let parseData = {};
  if (payload[0]) {
    const currentObj = payload[0];
    try {
      parseData = {
        ...parseData,
        yearOfExp: currentObj.totalExperience,
        yearOfExpUS: currentObj.usExperience,
        jobTitle: currentObj.jobTitle,
        client: currentObj.client,
        location: currentObj.location,
        projectSummary: currentObj.summary,
      };
      currentObj.fromDate &&
        currentObj.fromDate instanceof Date &&
        setFromDate(dateConverter(currentObj.fromDate));
      currentObj.toDate &&
        currentObj.toDate instanceof Date &&
        setToDate(dateConverter(currentObj.toDate));
    } catch {}
  }
  if (payload[1]) {
    try {
      setVisibility((state) => {
        return { ...state, one: true };
      });
      const currentObj = payload[1];

      parseData = {
        ...parseData,
        yearOfExp1: currentObj.totalExperience,
        yearOfExpUS1: currentObj.usExperience,
        jobTitle1: currentObj.jobTitle,
        client1: currentObj.client,
        domain1: currentObj.domain && currentObj.domain,
        location1: currentObj.location && currentObj.location,
        projectSummary1: currentObj.summary,
      };
      currentObj.fromDate &&
        currentObj.fromDate instanceof Date &&
        setFromDate1(dateConverter(currentObj.fromDate));
      currentObj.toDate &&
        currentObj.toDate instanceof Date &&
        setToDate1(dateConverter(currentObj.toDate));
    } catch {}
  }
  if (payload[2]) {
    try {
      setVisibility((state) => {
        return { ...state, two: true };
      });
      const currentObj = payload[2];
      parseData = {
        ...parseData,
        yearOfExp2: currentObj.totalExperience,
        yearOfExpUS2: currentObj.usExperience,
        jobTitle2: currentObj.jobTitle,
        client2: currentObj.client,
        location2: currentObj.location,
        projectSummary2: currentObj.summary,
      };
      currentObj.fromDate &&
        currentObj.fromDate instanceof Date &&
        setFromDate2(dateConverter(currentObj.fromDate));
      currentObj.toDate &&
        currentObj.toDate instanceof Date &&
        setToDate2(dateConverter(currentObj.toDate));
    } catch {}
  }
  setInformation({ ...information, ...parseData });
}

export {
  postExperienceParseData,
  getExperienceParseData,
  getExperienceFromParse,
};
