import "../../styles/app.scss";
import "reactjs-popup/dist/index.css";
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MatchingJob from "./MatchingJob";
import Applications from "./Applications";
import Setting from "./Setting";
import DashboardHome from "./DashboardHome";
import DashboardView from "./DashboardView";
import NavbarSidebar from "../../common-component/NavbarSidebar";
import NavbarTopbar from "../../common-component/NavbarTopbar";
import NavbarSidebarForNewUserCandidate from "../../common-component/NavbarSidebarForNewUserCandidate";
import Message from "../../common-component/Message";
import JobApply from "./Jobapply/JobApply";
import Profile from "./Profile";
import UploadProfile from "../recruiter/UploadProfile";
import RegistrationForm from "../recruiter/Resume-Builder/RegistrationForm";
import BuildResume2 from "../recruiter/Resume-Builder/BuildResume2";
import BuildResume3 from "../recruiter/Resume-Builder/BuildResume3";
import BuildResume4 from "../recruiter/Resume-Builder/BuildResume4";
import BuildResume5 from "../recruiter/Resume-Builder/BuildResume5";
import ResumeSnapshot from "../recruiter/ResumeSnapshot";
import RecProfile from "../recruiter/Profile";
import { getUserInfo } from "../../api/common/userInfo.js";
import CandidateListDetails from "../recruiter/CandidateListDetails";
import CandidateDetails from "../recruiter/CandidateDetails";
import Notification from "../../common-component/Notification";
import ViewDetails from "../../common-component/ViewDetails";
import { counting } from "../../api/common/countNotifications";
import { notification } from "../../api/common/notification";
import { SOCKET_URL } from "../../api/ENVIRONMENT";
import decode from "jwt-decode";
import io from "socket.io-client"
import RemoteJob from "./RemoteJob.jsx";
const Mainmenu = () => {
  // return mainmenu page of candidate from where all candidate are loaded
  const [userInfo, setUserInfo] = useState({
    candidateId: "",
    firstName: "",
    lastName: "",
    profilePicture: "",
  });
  const isnewuser = useSelector((state) => state.isnewuser);
  const isrecruiter = useSelector((state) => state.isrecruiter);
  const token = useSelector((state) => state.token);
  const socketVal = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const tablink = [
    "/candidate/registration",
    "/candidate/uploadprofile",
    "/candidate/resumeexperience",
    "/candidate/resumecertificate",
    "/candidate/resumesecurity",
    "/candidate/resumesocial",
    "/candidate/resumesnapshot",
  ];
  useEffect(() => {
    async function callItSelf() {
      try {
        const response = await getUserInfo(token);
        if (response.status === 200) {
          let data = await response.json();
          data = data.payload;
          const id = Object.keys(data).filter((key) => {
            if (key.endsWith("Id") && key !== "userId") return true;
            return false;
          });
          setUserInfo({
            candidateId: data[id],
            firstName:
              data.firstName.charAt(0).toUpperCase() +
              data.firstName.slice(1).toLowerCase(),
            lastName:
              data.lastName.charAt(0).toUpperCase() +
              data.lastName.slice(1).toLowerCase(),
            profilePicture: data.profilePicture,
          });
        }
      } catch { }
    }
    callItSelf();
  }, []);

  const [countNotification, setCountNotification] = useState();
  const [message, setMessage] = useState([]);

  // count notification
  useEffect(() => {
    async function notifyCount() {
      try {
        const response = await counting(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCountNotification(payload.count);
        }
        const notificationRespose = await notification(token, 1);
        if (notificationRespose.status === 200) {
          const data = await notificationRespose.json();
          const payload = data.payload;
          setMessage(payload);
        }
      } catch { }
    }
    notifyCount();
  }, []);

  useEffect(() => {
    if (socketVal.length === 0) {
      const socket = io.connect(SOCKET_URL, {
        auth: {
          userId: decode(token).uuid
        }
      });
      dispatch({ type: "setsocket", value: socket });
    }
  }, [])

  return (
    <div className={"container-fluid"}>
      <div className={"row"}>
        {/*first bar*/}
        {isnewuser ? (
          <NavbarSidebarForNewUserCandidate
            homelocation={"/candidate/dashboard/register"}
          />
        ) : (
          <NavbarSidebar homelocation={"/candidate/dashboardview"} />
        )}
        {/*second bar*/}
        <div className={"col pl-0"}>
          <NavbarTopbar
            candidateId={userInfo.candidateId}
            firstName={userInfo.firstName}
            lastName={userInfo.lastName}
            profilePicture={userInfo.profilePicture}
            profilelocation={"/candidate/profile"}
            settinglocation={"/candidate/setting"}
            allnotification={"/candidate/notification"}
            count={countNotification}
            message={message}
          />
          {/*dashboard page views goes here*/}
          <div
            className={"row h-100 stick"}
            style={{
              // backgroundColor: "#F6F4FF",
              borderRadius: "38px 0px 0px 0px",
            }}
          >
            <Route path={"/candidate/dashboard"}>
              <DashboardHome />
            </Route>
            <Route path={"/candidate/dashboardview"}>
              <DashboardView />
            </Route>
            <Route
              path={["/candidate/matchjobs", "/candidate/matchjobs/:title"]}
            >
              <MatchingJob />
            </Route>
            <Route
              path={[
                "/candidate/jobapply/:reqID/:status",
                "/candidate/jobapply/:reqID",
                "/candidate/jobapply",
              ]}
            >
              <JobApply />
            </Route>
            <Route path={"/candidate/applications"}>
              <Applications />
            </Route>
            <Route path={"/candidate/candidateListDetails"}>
              <CandidateListDetails />
            </Route>
            <Route
              path={[
                "/candidate/candidatedetails/:cadidateID/:checkStatus",
                "/candidate/candidatedetails/:cadidateID",
                "/candidate/candidatedetails",
              ]}
            >
              <CandidateDetails />
            </Route>
            <Route path={["/candidate/message/:id", "/candidate/message"]}>
              <Message homelocation={"/candidate/dashboardview"} />
            </Route>
            <Route path={"/candidate/profile"}>
              {isrecruiter ? (
                <RecProfile />
              ) : (
                <Profile headername={"Profile"} />
              )}
            </Route>
            <Route path={"/candidate/setting"}>
              <Setting />
            </Route>
            <Route path={"/candidate/notification"}>
              <Notification viewdetails={"/candidate/viewdetails"} />
            </Route>
            <Route path={"/candidate/viewdetails"}>
              <ViewDetails />
            </Route>
            {/*recruiter & freelance upload Profile*/}

            <Route
              path={["/candidate/registration/:id", "/candidate/registration"]}
            >
              <RegistrationForm link={tablink} />
            </Route>

            <Route path={"/candidate/uploadprofile/:id"}>
              <UploadProfile
                userregistration={"/candidate/resumeexperience"}
                snapshot={"/candidate/resumesnapshot"}
              />
            </Route>

            <Route path={"/candidate/resumeexperience/:id"}>
              <BuildResume2 link={tablink} />
            </Route>
            <Route path={"/candidate/resumecertificate/:id"}>
              <BuildResume3 link={tablink} />
            </Route>
            <Route path={"/candidate/resumesecurity/:id"}>
              <BuildResume4 link={tablink} />
            </Route>
            <Route path={"/candidate/resumesocial/:id"}>
              <BuildResume5 link={tablink} />
            </Route>
            <Route path={"/candidate/resumesnapshot/:id"}>
              <ResumeSnapshot />
            </Route>

            <Route path={"/candidate/remotejobs"}>
              <RemoteJob />
            </Route>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Mainmenu);
