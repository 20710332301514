import React, { useState, useEffect, useRef } from "react";
import GoToTop from "../common/GoToTop";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { candidateDetails } from "../../api/client/candidateDetails";
import { Link } from "react-router-dom";
import { reccandidateDetails } from "../../api/recruiter/client/candidateDetails";
import { freecandidateDetails } from "../../api/freelance/client/candidateDetails";
import { recrejectApplication } from "../../api/recruiter/client/rejectApplication";
import { freerejectApplication } from "../../api/freelance/client/rejectApplication";
import { rejectApplication } from "../../api/client/rejectApplication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { Puff } from 'react-loader-spinner';

const CandidateDetails = () => {
  // return jobapply header data
  const param = useParams();
  const id = param.cadidateID;
  const token = useSelector((state) => state.token);
  const [match, setMatch] = useState();
  const [rejectStatus, setRejectStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const rejectRef = useRef();

  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await reccandidateDetails(token, id)
          : isFreelance
            ? await freecandidateDetails(token, id)
            : await candidateDetails(token, id);
        let matchData = {};
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          if (payload) {
            payload.map((data) => {
              let obj = data.Candidate;
              let exp = obj.CandidateExperiences;
              matchData = {
                uuid: data.uuid,
                status: data.status,
                id: obj.candidateId,
                userId: obj.userId,
                employType: obj.preferredEmployment,
                firstName: obj.firstName,
                middleName: obj.middleName !== "null" ? "" : obj.middleName,
                lastName: obj.lastName,
                rate: obj.rate,
                rateType: obj.rateType,
                resumeTitle: obj.resumeTitle,
                matching: data.matchPercentage,
                rejected: data.rejected,
                primarySkill: obj.CandidatePrimarySkills[0].skill,
                secondarySkill: obj.CandidateSecondarySkills.length
                  ? obj.CandidateSecondarySkills[0].skill
                  : "",
                qualification: obj.CandidateEducations[0].academicQualification,
                specialization: obj.CandidateEducations[0].specialization
                  ? obj.CandidateEducations[0].specialization
                  : "",
                expJobTitle: exp[0].jobTitle,
                expClient: exp[0].client,
                expUSA: exp[0].totalYearOfExperienceInUS,
                expTotal: exp[0].totalYearOfExperience,
                expLocation: exp[0].location,
              };
              if (obj.CandidatePrimarySkills.length === 2) {
                matchData = {
                  ...matchData,
                  primarySkill1: obj.CandidatePrimarySkills[1].skill,
                };
              }
              if (obj.CandidatePrimarySkills.length === 3) {
                matchData = {
                  ...matchData,
                  primarySkill1: obj.CandidatePrimarySkills[1].skill,
                  primarySkill2: obj.CandidatePrimarySkills[2].skill,
                };
              }
              if (obj.CandidatePrimarySkills.length === 4) {
                matchData = {
                  ...matchData,
                  primarySkill1: obj.CandidatePrimarySkills[1].skill,
                  primarySkill2: obj.CandidatePrimarySkills[2].skill,
                  primarySkill3: obj.CandidatePrimarySkills[3].skill,
                };
              }
              if (obj.CandidateSecondarySkills.length === 2) {
                matchData = {
                  ...matchData,
                  secondarySkill1: obj.CandidateSecondarySkills[1].skill,
                };
              }
              if (obj.CandidateSecondarySkills.length === 3) {
                matchData = {
                  ...matchData,
                  secondarySkill1: obj.CandidateSecondarySkills[1].skill,
                  secondarySkill2: obj.CandidateSecondarySkills[2].skill,
                };
              }
              if (obj.CandidateSecondarySkills.length === 4) {
                matchData = {
                  ...matchData,
                  secondarySkill1: obj.CandidateSecondarySkills[1].skill,
                  secondarySkill2: obj.CandidateSecondarySkills[2].skill,
                  secondarySkill3: obj.CandidateSecondarySkills[3].skill,
                };
              }
              if (exp.length === 2) {
                matchData = {
                  ...matchData,
                  expJobTitle1: exp[1].jobTitle,
                  expClient1: exp[1].client,
                  expUSA1: exp[1].totalYearOfExperienceInUS,
                  expTotal1: exp[1].totalYearOfExperience,
                  expLocation1: exp[1].location,
                };
              }
              if (exp.length === 3) {
                matchData = {
                  ...matchData,
                  expJobTitle1: exp[1].jobTitle,
                  expClient1: exp[1].client,
                  expUSA1: exp[1].totalYearOfExperienceInUS,
                  expTotal1: exp[1].totalYearOfExperience,
                  expLocation1: exp[1].location,
                  expJobTitle2: exp[2].jobTitle,
                  expClient2: exp[2].client,
                  expUSA2: exp[2].totalYearOfExperienceInUS,
                  expTotal2: exp[2].totalYearOfExperience,
                  expLocation2: exp[2].location,
                };
              }
              if (obj.CandidateEducations.length === 2) {
                matchData = {
                  ...matchData,
                  qualification1:
                    obj.CandidateEducations[1].academicQualification,
                  specialization1: obj.CandidateEducations[1].specialization
                    ? obj.CandidateEducations[1].specialization
                    : "",
                };
              }
              if (obj.CandidateEducations.length === 3) {
                matchData = {
                  ...matchData,
                  qualification1:
                    obj.CandidateEducations[1].academicQualification,
                  specialization1: obj.CandidateEducations[1].specialization
                    ? obj.CandidateEducations[1].specialization
                    : "",
                  qualification2:
                    obj.CandidateEducations[2].academicQualification,
                  specialization2: obj.CandidateEducations[2].specialization
                    ? obj.CandidateEducations[2].specialization
                    : "",
                };
              }
              setMatch({ ...match, ...matchData });
              return null;
            });
          }
        }
      } catch { }
    }
    callUserDetails();
  }, []);
  //reject
  async function handleSingleReject() {
    if (rejectRef.current) {
      rejectRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true);
    var data = {
      UUID: [id],
    };
    const response = isrecruiterCandidate
      ? await recrejectApplication(token, data)
      : isFreelance
        ? await freerejectApplication(token, data)
        : await rejectApplication(token, data);
    if (response.status === 200) {
      toast.error("Rejected");
      setRejectStatus(true);
      rejectRef.current.removeAttribute("disabled");
      setLoader(false);
    } else {
      toast.error("Some error rises");
      rejectRef.current.removeAttribute("disabled");
      setLoader(false);
    }
  }

  return (
    <>
      {!match ? (
        // <div
        //   className="row h-100 background-colored-Pale-gray matchingJob-loader-container"
        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       className={"mx-auto d-block"}
        //       alt={"loader"}
        //     />
        //   </div>
        // </div>
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
      ) : (
        <div className={"col mx-lg-5 mx-2"}>
          <ToastContainer position={"top-center"} />
          <div className={"row pt-5 pb-5"}>
            <div className={"contentholder w-100 pl-3 pb-5"}>
              <div className="row">
                <font className=" colored-Deep-charcoal ml-4 mt-4 font-size-15rem font-weight-600 colored-primary-black"
                //  style={{
                //   fontSize: '1.5rem',
                //   color: 'black',
                //   fontWeight: 600
                // }}
                >
                  {match ? match.firstName : ""}&nbsp;
                  {match ? match.lastName : ""}
                </font>
              </div>
              <div className={"row mt-2"}>
                <div className={"col-md-8 pl-4"}>
                  <label
                    className="  font-family-roboto font-size-1rem font-weight-600 colored-neutral-gray"
                  //  style={{ fontWeight: '600', color: '#989898' }}
                  >
                    Candidate ID - <span className="font-size-1rem font-family-roboto font-weight-600 colored-neutral-gray"
                    //  style={{ fontWeight: '600', color: '#989898' }}
                    >{match ? match.id : ""}{" "}</span>
                  </label>
                </div>

                <div className={"col-md  pr-0"}>
                  {/* <div
                className={"badge py-2"}
                style={{
                  backgroundColor: "#D3D3D3",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {match ? match.matching : ""}%
              </div> */}

                </div>
              </div>
              <div className="row">
                <div className={"col-lg-4 pl-4"}>
                  <font className="font-size-09rem colored-Deep-charcoal"  >
                    ${match ? match.rate : ""}/{match ? match.rateType : ""}
                  </font>
                  <br />
                </div>
              </div>
              <div className="row">
                {/* <div className={"col-md-1 pl-4"}>
            
            </div> */}
                <div className="col-lg-12 mt-2 pl-4"  >
                  {/* <CircularProgressbar
                value={match ? match.matching : ''}
                text={`${match ? match.matching : ''}%`}
                styles={buildStyles({
                  textColor: "#000",
                  pathColor: "#5B4FB7",
                  trailColor: "#f9f9f9",
                })}
              /> */}
                  <label
                    className="colored-Pale-silver font-weight-500 font-size-16px "

                  >
                    Matching
                  </label>
                  <span className=" mt-3 ml-4">{match ? match.matching : ''}%</span>
                </div>
              </div>
              <div className={"row align-items-center pt-1  pl-3"}>
                <div className={"col-lg-8 "}>
                  <font className="colored-Royal-blue font-size-09rem font-weight-600 colored-primary-black" //style={{ fontWeight: '600', color: '#000000' }}
                  >
                    {match ? match.employType : ""}
                  </font>
                  {/* <br /> */}

                  <br />
                </div>

              </div>
              <div className={"row pl-3"}>
                <div className={"col"}>
                  <font className="font-size-08 colored-Deep-charcoal" >
                    {match ? match.resumeTitle : ""}
                  </font>
                </div>
              </div>
              {/*second row*/}
              <div className={"row pl-3 pt-2"}>
                <div className={"col-md"}>
                  <img
                    src="../../icon/candidate/applications/Group348.png"
                    alt={"correction icon"}
                    className={"img-fluid pr-3"}
                    height={30}
                    width={32}
                  />
                  <font className={"parafont font-size-09rem font-weight-600"} >
                    Primary Skill
                  </font>
                </div>
              </div>
              <div className={"row ml-3"}>
                <div
                  className={"col-md parafont ml-4 font-weight-normal font-size-08"}

                >
                  <font>{match ? match.primarySkill : ""}</font>
                  <br />
                  {match && !match.primarySkill1 ? (
                    ""
                  ) : (
                    <>
                      <font>{match ? match.primarySkill1 : ""}</font>
                      <br />
                    </>
                  )}
                  {match && !match.primarySkill2 ? (
                    ""
                  ) : (
                    <>
                      <font>{match ? match.primarySkill2 : ""}</font>
                      <br />
                    </>
                  )}
                  {match && !match.primarySkill3 ? (
                    ""
                  ) : (
                    <>
                      <font>{match ? match.primarySkill3 : ""}</font>
                      <br />
                    </>
                  )}
                </div>
              </div>
              {match && !match.secondarySkill ? (
                ""
              ) : (
                <>
                  <div className={"row pt-2 pl-3"}>
                    <div className={"col-md"}>
                      <img
                        src="../../icon/candidate/applications/Group348.png"
                        alt={"correction icon"}
                        className={"img-fluid pr-3"}
                        height={30}
                        width={32}
                      />
                      <font className={"parafont font-weight-600 font-size-09rem"}>Secondary Skill</font>
                    </div>
                  </div>
                  <div className={"row pt-2 ml-4"}>
                    <div
                      className={"col-md parafont ml-3 font-size-08 font-weight-normal"}

                    >
                      <font >{match ? match.secondarySkill : ""}</font>
                      <br />
                      {match && !match.secondarySkill1 ? (
                        ""
                      ) : (
                        <>
                          <font>{match ? match.secondarySkill1 : ""}</font>
                          <br />
                        </>
                      )}
                      {match && !match.secondarySkill2 ? (
                        ""
                      ) : (
                        <>
                          <font>{match ? match.secondarySkill2 : ""}</font>
                          <br />
                        </>
                      )}
                      {match && !match.secondarySkill3 ? (
                        ""
                      ) : (
                        <>
                          <font>{match ? match.secondarySkill3 : ""}</font>
                          <br />
                        </>
                      )}
                      {match && !match.secondarySkill4 ? (
                        ""
                      ) : (
                        <>
                          <font>{match ? match.secondarySkill4 : ""}</font>
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className={"row pt-2 pl-3"}>
                <div className={"col-md"}>
                  <img
                    src="../../icon/candidate/applications/Group348.png"
                    alt={"correction icon"}
                    className={"img-fluid pr-3"}
                    height={30}
                    width={32}
                  />
                  <font className={"parafont font-size-09rem font-weight-600 "} style={{

                  }}>Experience</font>
                </div>
              </div>
              <div className={"row pt-2 ml-3"}>
                <div className={"col-md ml-4"}>
                  <font className="parafont  font-weight-normal font-size-08"  >
                    <font className={"font-weight-600 font-size-08"}>
                      Years Of Experience In USA :
                    </font>
                    {match ? match.expUSA : ""}
                  </font>
                  <br />
                  <font className={"parafont font-weight-normal font-size-08"}>
                    <font className={"font-weight-600 font-size-08"}>
                      Total Years Of Experience :{" "}
                    </font>
                    {match ? match.expTotal : ""}
                  </font>
                  <br />
                  <font className={"parafont font-weight-normal font-size-08"}>
                    <font className={"font-weight-600 font-size-08"}>Job Title : </font>
                    {match ? match.expJobTitle : ""}
                  </font>
                  <br />
                  <font className={"parafont font-weight-normal font-size-08"}>
                    <font className={"font-weight-600 font-size-08"}>Client : </font>
                    {match ? match.expClient : ""}
                  </font>
                  <br />
                  <font className={"parafont font-weight-normal font-size-08"}>
                    <font className={"font-weight-600 font-size-08"}>Location : </font>
                    {match ? match.expLocation : ""}
                  </font>
                  <br />
                  {/* more experience */}
                  {match && !match.expUSA1 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">
                          Years Of Experience In USA :
                        </font>
                        {match ? match.expUSA1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expTotal1 ? (
                    ""
                  ) : (
                    <>
                      <font className="parafont font-size-08 font-weight-normal"  >
                        <font className="font-weight-600 font-size-08">
                          Total Years Of Experience :
                        </font>
                        {match ? match.expTotal1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expJobTitle1 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">Job Title :</font>
                        {match ? match.expJobTitle1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expClient1 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">Client :</font>
                        {match ? match.expClient1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expLocation1 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">Location :</font>
                        {match ? match.expLocation1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {/*more experience */}
                  {match && !match.expUSA2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">
                          Years Of Experience In USA :
                        </font>
                        {match ? match.expUSA2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expTotal2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">
                          Total Years Of Experience :
                        </font>
                        {match ? match.expTotal2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expJobTitle2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">Job Title :</font>
                        {match ? match.expJobTitle2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expClient2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">Client :</font>
                        {match ? match.expClient2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.expLocation2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        <font className="font-weight-600 font-size-08">Location :</font>
                        {match ? match.expLocation2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                </div>
              </div>
              <div className={"row pt-2 pl-3"}>
                <div className={"col-md"}>
                  <img
                    src="../../icon/candidate/applications/Group348.png"
                    alt={"correction icon"}
                    className={"img-fluid pr-3"}
                    height={30}
                    width={32}
                  />
                  <font className={"parafont font-size-09rem font-weight-600"} >Academics Qualification</font>
                </div>
              </div>
              <div className={"row pt-2 ml-4"}>
                <div className={"col-md ml-3"}>
                  <font className={"parafont font-weight-normal font-size-08"}>
                    {match ? match.qualification : ""}
                  </font>
                  <br />
                  <font className={"parafont font-weight-normal font-size-08"}>
                    {match ? match.specialization : ""}
                  </font>
                  <br />
                  {match && !match.qualification1 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        {match ? match.qualification1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.specialization1 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        {match ? match.specialization1 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.qualification2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        {match ? match.qualification2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                  {match && !match.specialization2 ? (
                    ""
                  ) : (
                    <>
                      <font className={"parafont font-size-08 font-weight-normal"}>
                        {match ? match.specialization2 : ""}
                      </font>
                      <br />
                    </>
                  )}
                </div>
              </div>
              <div className={"row mt-1"}>
                <div className={"col-md offset-sm-9 offset-2"}>
                  {match && match.status === null ? (
                    <Link
                      to={`/client/interview/${match ? match.userId : ""}/${match ? match.uuid : ""
                        }`}
                      className={"btn background-colored-Lavender-slate colored-primary-white border-radius-10px  candidateDetails-Interview "}

                    >
                      Interview
                    </Link>
                  ) : (
                    <label
                      className={"btn mt-2 background-colored-Mint-green border-radius-10px colored-primary-white candidateDetails-status"}
                    >
                      {match ? match.status : ""}
                    </label>
                  )}
                  {match && match.rejected ? (
                    <button
                      type={"button"}
                      className={"btn ml-5 rejectbtn border-radius-10px background-colored-Fiery-red candidateDetails-Rejected colored-primary-white"}

                    >
                      Rejected
                    </button>
                  ) : (
                    <>
                      {rejectStatus ? (
                        <button
                          type={"button"}
                          className={"btn ml-5 rejectbtn border-radius-10px background-colored-Fiery-red candidateDetails-Rejected colored-primary-white"}
                          ref={rejectRef}

                        >
                          Rejected
                        </button>
                      ) : (
                        <button
                          type={"button"}
                          className={"btn ml-4 rejectbtn border-radius-10px background-colored-Fiery-red candidateDetails-Reject colored-primary-white"}
                          onClick={() => handleSingleReject()}
                          ref={rejectRef}
                        >
                          {loader ? (
                            <i
                              className="fa fa-refresh fa-spin Candidate-Details-loader-padding colored-primary-black "

                            ></i>
                          ) : (
                            ""
                          )}
                          Reject
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <GoToTop />
        </div>
      )}
    </>
  );
};

export default React.memo(CandidateDetails);
